// import logo from './assets/images/full_logo@0.5x.png'

import logo from './assets/images/ihelp-logo.svg'
import footerLogo from './assets/images/usc-alliance-logo.png'
import allianceMember from './assets/images/alliance-member.svg'

import shared from '../shared/assets'

const { cie, headerIcons, healthengine, flags } = shared.defaults

import '@fontsource/rubik/300.css'
import '@fontsource/rubik/400.css'
import '@fontsource/rubik/500.css'
import '@fontsource/rubik/600.css'
import '@fontsource/rubik/700.css'

import '@fortawesome/fontawesome-pro/css/brands.css'

export const assets = {
  logo,
  footerLogo,
  allianceMember,
  cie,
  headerIcons,
  healthengine,
  flags,
}
